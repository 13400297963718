<template>
  <a-upload :file-list="fileList"
            :show-upload-list="false"
            accept=".ppt,.pptx"
            :customRequest="uploadCustomer"
            :before-upload="beforeUpload"
            @change="handleChange">
    <a-button class="mb-8">
      <PlusOutlined />上传课件
    </a-button>
  </a-upload>
</template>

<script>
import _ from "lodash";
import { PlusOutlined } from "@ant-design/icons-vue";
import axios from "axios";

import liveApi from "@/service/api/live";
import getEtag from "@/utils/getLessonHash";

export default {
  name: "UploadLesson",

  components: {
    PlusOutlined,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
    },

    roomId: {
      type: [String, Number],
    },
  },

  emits: ["update:data"],

  data() {
    return {
      fileList: [],
      file: {},
    };
  },
  watch: {
    data: {
      handler(value) {
        this.fileList = value;
      },
      deep: true,
      immediate: true,
    },
  },

  methods: {
    beforeUpload(file) {
      const { name, size } = file;

      const index = name.lastIndexOf(".");
      const type = name.substring(index, name.length);

      const isPpt = _.includes([".ppt", ".pptx"], _.toLower(type));

      if (!isPpt) {
        this.$message.error("只能上传ppt或pptx格式文件！");
        return false;
      }

      const MB_UNIT = 1024 * 1024;
      const isLt20M = size <= MB_UNIT * 20;

      if (!isLt20M) {
        this.$message.error("文件大小必须小于20MB！");
        return false;
      }

      return true;
    },

    uploadCustomer(file) {
      this.file = file;

      const { file: fileInfo } = file;

      const reader = new FileReader();
      reader.onload = async (event) => {
        if (event.target?.result) {
          const hash = await getEtag(event.target?.result);

          await liveApi
            .coursewareStartUpload({
              name: fileInfo.name,
              roomId: this.roomId,
              hash,
            })
            .then((res) => {
              file.onSuccess(res);
            })
            .catch(() => {
              file.onError("课件已存在");
            });
        }
      };

      reader.readAsDataURL(fileInfo);
    },

    handleChange({ file, fileList }) {
      const { status, response } = file;

      if (status === undefined) {
        return;
      }

      if (status === "done" && !response?.uploaded) {
        if (!response.form) {
          this.$emit("update:data", fileList);
          return;
        }

        this.handleUploaded({ file, fileList });
        return;
      }

      this.$emit("update:data", fileList);
    },

    handleUploaded({ file, fileList }) {
      const {
        response: { form, uploadId },
      } = file;

      const formData = new FormData();
      formData.append("file", this.file.file);
      formData.append("key", form.key);
      formData.append("token", form.token);

      axios
        .post(form.url, formData)
        .then(async () => {
          const lesson = await liveApi
            .coursewareFinishUpload({
              id: uploadId,
              roomId: this.roomId,
            })
            .catch((error) => {
              this.file.onError(error);
            });

          this.file.onSuccess(lesson);
          this.$emit("update:data", fileList);
        })
        .catch((error) => {
          this.file.onError(error);
        });
    },
  },
};
</script>
<style  scoped>
</style>