/**
 * 取文件hash值
 * @param buffer
 */
import JsSHA from "jssha";

export default function getEtag(buffer) {
  // 以4M为单位分割
  const blockSize = 4 * 1024 * 1024;
  const sha1String = [];
  let prefix = 0x16;
  let blockCount = 0;
  let bufferSize;
  if (typeof buffer === 'string') {
    bufferSize = buffer.length;
  }
  else {
    bufferSize = buffer.byteLength;
  }
  blockCount = Math.ceil(bufferSize / blockSize);
  for (let i = 0; i < blockCount; i++) {
    const shA1 = new JsSHA("SHA-1", "UINT8ARRAY");
    shA1.update(buffer.slice(i * blockSize, (i + 1) * blockSize));
    sha1String.push(shA1.getHash("UINT8ARRAY"));
  }
  function concatArr2Uint8(s) {
    let length = 0;
    s.forEach(i => {
      length += i.length;
    });
    const tmp = new Uint8Array(length);
    let offset = 0;
    s.forEach(i => {
      tmp.set(i, offset);
      offset += i.length;
    });
    return tmp;
  }
  function Uint8ToBase64(u8Arr, uriSafe) {
    const CHUNK_SIZE = 0x8000; //arbitrary number
    let index = 0;
    const length = u8Arr.length;
    let result = '';
    while (index < length) {
      const slice = u8Arr.subarray(index, Math.min(index + CHUNK_SIZE, length));
      result += String.fromCharCode.apply(null, slice);
      index += CHUNK_SIZE;
    }
    return uriSafe ? btoa(result).replace(/\//g, '_').replace(/\+/g, '-') : btoa(result);
  }
  function calcEtag() {
    if (!sha1String.length)
      return 'Fto5o-5ea0sNMlW_75VgGJCv2AcJ';
    let sha1Buffer = concatArr2Uint8(sha1String);
    // 如果大于4M，则对各个块的sha1结果再次sha1
    if (blockCount > 1) {
      prefix = 0x96;
      const shA1 = new JsSHA("SHA-1", "ARRAYBUFFER");
      shA1.update(sha1Buffer.buffer);
      sha1Buffer = shA1.getHash("UINT8ARRAY");
    }
    else {
      // eslint-disable-next-line
      sha1Buffer = Array.apply([], sha1Buffer);
    }
    sha1Buffer = concatArr2Uint8([new Uint8Array([prefix]), sha1Buffer]);
    return Uint8ToBase64(sha1Buffer, true);
  }
  return calcEtag();
}