<template>
  <a-spin :spinning="pageLoaidng">

    <a-form :model="form"
            :colon="false"
            :labelCol="{style: 'width: 96px'}"
            :wrapperCol="{span: 8}">

      <!-- 引流活码 -->
      <a-form-item>
        <template #label>
          引流活码
          <a-popover placement="bottom">
            <template #content>
              引导用户扫码加员工好友
            </template>
            <svg-icon class="text-icon"
                      type="iconyiwen" />
          </a-popover>
        </template>

        <a-switch v-model:checked="form.livecode.status"
                  checked-children="开"
                  un-checked-children="关"
                  @change="saveSetting('channelCode', true)" />
      </a-form-item>

      <div v-show="form.livecode.status">
        <a-form-item class="ml-96"
                     label="选择活码"
                     :labelCol="{style: 'width: 64px'}">
          <template #help
                    v-if="!form.livecode.avatar">
            <p class="my-12 text-color-999">请选择活码，如果没有选择，则默认关闭引流活码</p>
          </template>

          <div v-if="!form.livecode.avatar"
               class="liveCode-upload__container"
               @click="selectLiveCodeModalVisible = true">
            <plus-outlined class="text-24 text-icon" />
            <span class="text-14 text-color-999">选择活码</span>
          </div>

          <div v-else
               class="livecode__show flex flex-col relative text-color-333">
            <div class="flex items-end ">
              <a-image :src="selectedCode.avatar"
                       alt="渠道活码"
                       width="100px"
                       height="100px" />

              <span class="flex-shrink-0 ml-16 text-14 text-admin-primary cursor-pointer"
                    @click="selectLiveCodeModalVisible = true">重新选择</span>
            </div>

            <span class="mt-8 text-14 line-clamp-2">
              {{ selectedCode.codeName }}
            </span>
          </div>
        </a-form-item>

        <a-form-item class="ml-96"
                     label="引导文案"
                     :labelCol="{style: 'width: 64px'}">
          <div ref="livecode_container"
               class="flex flex-col p-16 text-color-999 bg-color-f5"
               style="line-height: initial;">
            入口引导语
            <div class="mt-8 mb-16">
              <a-form-item class="mb-0">
                <a-popover placement="right">
                  <template #content>
                    <div class="relative">
                      <img src="@/assets/operation-setting-livecode.png"
                           alt="入口欢迎语背景图"
                           style="width: 300px; height: 625px;">
                      <p class="absolute text-12 text-color-f"
                         style="top: 367px; left: 48px;">
                        {{ form?.livecode?.introduction }}</p>
                    </div>
                  </template>

                  <a-input v-model:value="form.livecode.introduction"
                           placeholder="如：扫码免费领取资料"
                           style="width: 260px;"
                           @change="handleChannelCodeChange('introduction')">
                    <template #suffix>
                      {{ form?.livecode?.introduction?.length || 0 }}/16
                    </template>
                  </a-input>
                </a-popover>
              </a-form-item>

            </div>

            二维码标题
            <div class="mt-8">
              <a-form-item class="mb-0"
                           :name="['livecode', 'codeName']">
                <a-popover placement="right">
                  <template #content>
                    <div class="relative">
                      <img src="@/assets/operation-setting-qrcode.png"
                           alt="二维码标题背景图"
                           style="width: 300px; height: 625px;">
                      <div class="absolute flex flex-col items-center p-16 text-12 text-color-333 text-center"
                           style="top: 333px; left: 20px; width: 260px;">
                        {{ form?.livecode?.title }}
                        <img class="my-16 "
                             :src="selectedCode?.avatar || require('@/assets/ErrorImg.png')"
                             alt="二维码图"
                             style="width: 120px;">
                        长按识别二维码，或保存图片
                      </div>
                    </div>
                  </template>
                  <a-input v-model:value="form.livecode.title"
                           placeholder="如：专属客服二维码"
                           style="width: 260px;"
                           @change="handleChannelCodeChange('title')">
                    <template #suffix>
                      {{ form?.livecode?.title?.length || 0 }}/30
                    </template>
                  </a-input>
                </a-popover>
              </a-form-item>
            </div>
          </div>
        </a-form-item>
      </div>

      <!-- 开课提醒 -->
      <a-form-item>
        <template #label>
          开课提醒
          <a-popover placement="bottom">
            <template #content>
              通过公众号向学员发送开课提醒
            </template>
            <svg-icon class="text-icon"
                      type="iconyiwen" />
          </a-popover>
        </template>

        <a-switch v-model:checked="form.lessonRemain.status"
                  :disabled="!isAccountbind"
                  @change="saveSetting('lessonRemain', true)"
                  checked-children="开"
                  un-checked-children="关" />

        <a-popover placement="right">
          <template #content>
            <div style="width: 500px;">
              <h3>直播公开课提醒设置教程</h3>

              <div class="mt-12">
                <p class="text-color-333 font-medium">
                  第一步：公众号开启订阅通知
                </p>
                <div class="pl-8 text-color-999">
                  <p style="text-indent: 1rem;">登录微信服务号的公众平台 https://mp.weixin.qq.com/，查看左侧是否有菜单「广告与服务」-「订阅通知」。</p>
                  <p style="text-indent: 1rem;">如果有「订阅通知」菜单，忽略本步骤。</p>
                  <p style="text-indent: 1rem;">如果没有「订阅通知」菜单，点击左侧最下方「+新的功能」。在服务列表中找到「订阅通知」，并开通。开通时服务类目选择”教育服务>在线教育”。</p>
                </div>
              </div>

              <div class="mt-12">
                <p class="text-color-333 font-medium">
                  第二步：销客助手绑定公众号
                </p>
                <p class="pl-8 text-color-999"
                   style="text-indent: 1rem;"> 在销客助手后台「企业管理」-「系统设置」-「公众号」-「绑定销客助手」页面，授权微信公众号绑定销客助手。 </p>
              </div>

              <div class="mt-12">
                <p class="text-color-333 font-medium">
                  第三步：开启直播提醒
                </p>
                <div class="pl-8 text-color-999">
                  <p style="text-indent: 1rem;">在直播课「运营设置」页面找到「开课提醒」，并选择开启；</p>
                  <p style="text-indent: 1rem;">设置直播开始前多少分钟发送提醒，如“15分钟前”。</p>
                </div>

              </div>

              <div class="mt-12">
                <p class="text-color-333 font-medium">
                  学员端如何收到提醒？
                </p>
                <div class="pl-8 text-color-999">
                  <p style="text-indent: 1rem;">学员进入直播介绍页，点击「开播提醒」按钮，系统弹出确认选项，学员选择“允许”即预约成功。</p>
                  <p style="text-indent: 1rem;">直播开始前，学员微信会收到一条服务通知，学员点击通知消息进入直播间。</p>
                </div>
              </div>

            </div>

          </template>
          <span class="ml-16 cursor-pointer text-admin-primary">使用教程</span>
        </a-popover>

        <div v-if="!isAccountbind"
             class="text-color-999">
          <svg-icon class="mr-8 text-12"
                    type="icontishi1" />
          未开启公众号，无法发送提醒。
          <router-link :to="{path: '/enterprise/systemSettingOfficialAccount'}">
            <span class="ml-8 text-admin-primary cursor-pointer">去开启</span>
          </router-link>
        </div>

        <div v-show="form.lessonRemain.status">
          直播开始
          <a-select ref="select"
                    v-model:value.number="form.lessonRemain.remaindTime"
                    :options="lessonRemainSelectOption"
                    style="margin: 0 8px; width: 120px;"
                    @select="saveSetting('lessonRemain', true)" />
          发送提醒消息
        </div>

      </a-form-item>

      <!-- 显示商品 -->
      <a-form-item :wrapperCol="{flex: 1}">
        <template #label>
          显示商品
          <a-popover placement="bottom">
            <template #content>
              在直播间直接售卖商品，即直播带货
            </template>
            <svg-icon class="text-icon"
                      type="iconyiwen" />
          </a-popover>
        </template>

        <a-switch v-model:checked="goodsStatus"
                  @change="handleGoodsStatusChange"
                  checked-children="开"
                  un-checked-children="关" />
      </a-form-item>

      <a-table v-show="goodsStatus"
               class="goods-table"
               :dataSource="goodsData"
               :columns="goodsColumns"
               row-key="id"
               bordered
               :pagination="false">

        <template #name="{ record }">

          <p class="line-clamp-2">{{ record.title }}</p>
        </template>

        <template #type="{ text }">{{ goodsType[text] }}</template>

        <template #price="{ text }">
          {{ Number(text / 100) }}
        </template>

        <template #action="{ record }">
          <a-button class="text-color-999"
                    type="link"
                    @click="handleDeleteGoods(record)">移除</a-button>
        </template>

        <template #footer>
          <a-button @click="selectGoodsModalVisible = true">
            <PlusOutlined />选择商品
          </a-button>
          <p v-show="goodsStatus"
             class="mt-8 text-color-999">请至少选择一个商品，如果没有选择，则默认关闭显示商品</p>
        </template>
      </a-table>
    </a-form>
  </a-spin>

  <select-scrm-goods-modal v-model:visible="selectGoodsModalVisible"
                           :selectedGoods="goodsData"
                           :loading="goodsLoading"
                           @confirm="handleSelectGoods" />

  <select-live-code-modal v-model:visible="selectLiveCodeModalVisible"
                          :selectedItem="selectedCode"
                          @confirm="handleSelectCode" />

</template>

<script>
import { defineComponent, ref, reactive, watch } from "vue";
import { Switch, Popover, Image, message } from "ant-design-vue";
import _ from "lodash";
import { useRoute } from "vue-router";

import SelectScrmGoodsModal from "@/views/liveOpenClass/statictisSubpages/SelectScrmGoodsModal";
import SelectLiveCodeModal from "@/views/liveOpenClass/statictisSubpages/SelectLiveCodeModal";
import SvgIcon from "@/components/SvgIcon";

import corpSettingApi from "@/service/api/corpSetting";
import liveApi from "@/service/api/live";

export default defineComponent({
  name: "liveStatisticOperationSetting",

  props: {
    liveRoom: {
      type: Object,
      default: () => {},
      required: true,
    },
    config: {
      type: [Array, String],
      default: () => [],
    },
  },

  emits: [],

  components: {
    ASwitch: Switch,
    APopover: Popover,
    AImage: Image,

    SelectScrmGoodsModal,
    SelectLiveCodeModal,
    SvgIcon,
  },

  setup(props) {
    const route = new useRoute();
    const { id: liveRoomId } = route.query;
    const pageLoaidng = ref(true);

    const form = reactive({
      livecode: {
        status: false,
        introduction: "扫码获取更多信息和服务",
        codeName: "",
        title: "",
        avatar: "",
        name: "channelCode",
      },
      lessonRemain: {
        status: false,
        remaindTime: 15,
        name: "lessonRemain",
      },
    });

    // 初始化数据
    const configEnum = {
      channelCode: "livecode",
      lessonRemain: "lessonRemain",
    };

    const initConfig = (config) => {
      _.forEach(config, (option) => {
        const _option = _.cloneDeep(option);
        _.assign(_option, _option.value);
        delete _option.value;

        const itemName = configEnum[_option.name];
        _.assign(form[itemName], _option);
      });

      if (form.livecode.id) {
        initCodeAvatar();
      }

      pageLoaidng.value = false;
    };

    const selectedCode = reactive({});

    const initCodeAvatar = () => {
      _.assign(selectedCode, form.livecode);
    };

    watch(
      () => props.config,
      (value) => {
        console.log(value);
        if (!value || !value.length) {
          pageLoaidng.value = false;
          return;
        }

        initConfig(value);
      },
      { immediate: true }
    );

    // 保存配置
    const saveSetting = async (type, isAlert) => {
      const target = form[configEnum[type]];
      const params = _.cloneDeep({
        ...target,
        liveRoomId,
      });

      if (type === "channelCode" && target.status) {
        isAlert = form.livecode.avatar ? true : false;
        params.status = !!form.livecode.avatar;
      }
      const cb = await liveApi.settingSave(params);
      target.id = cb.id;

      if (isAlert) {
        message.success("保存成功");
      }
    };

    // 活码选择
    const selectLiveCodeModalVisible = ref(false);

    const handleSelectCode = (code) => {
      const cloneCode = _.cloneDeep(code);

      _.assign(selectedCode, cloneCode);
      _.assign(form.livecode, cloneCode);

      saveSetting("channelCode", true);

      selectLiveCodeModalVisible.value = false;
    };

    const handleChannelCodeChange = _.debounce((value) => {
      const { title, introduction } = form.livecode;

      form.livecode.introduction = introduction.slice(0, 16);
      form.livecode.title = title.slice(0, 30);

      if (value === "introduction" && !_.trim(form.livecode.introduction)) {
        message.error("该项不能为空,请重试");
        return;
      }

      saveSetting("channelCode", true);
    }, 800);

    // 开课提醒
    const lessonRemainSelectOption = [
      {
        label: "直播开始时",
        value: 0,
      },
      {
        label: "5分钟前",
        value: 5,
      },
      {
        label: "15分钟前",
        value: 15,
      },
      {
        label: "30分钟前",
        value: 30,
      },
      {
        label: "60分钟前",
        value: 60,
      },
      {
        label: "1天前",
        value: 1440,
      },
    ];

    const isAccountbind = ref(true);

    const getAccountBind = async () => {
      const res = await corpSettingApi.getConfigurations();

      isAccountbind.value = res.setWechatConf.wechatPlatformStatus === "open";
    };

    getAccountBind();

    // 商品选择
    const goodsStatus = ref(false);

    const goodsData = ref([]);

    watch(
      () => props.liveRoom?.goods,
      (value) => {
        goodsData.value = value || [];

        goodsStatus.value = !!props.liveRoom.productIsopen;
      },
      { immediate: true }
    );

    const goodsColumns = [
      {
        title: "商品名称",
        dataIndex: "title",
        slots: {
          customRender: "name",
        },
        width: "40%",
      },
      {
        title: "商品类型",
        dataIndex: "type",
        slots: {
          customRender: "type",
        },
      },
      {
        title: "售价（元）",
        dataIndex: "price",
        slots: {
          customRender: "price",
        },
      },
      {
        title: "操作",
        dataIndex: "action",
        slots: {
          customRender: "action",
        },
      },
    ];

    const goodsType = {
      classroom: "网校班级",
      course: "网校课程",
      normal: "普通商品",
    };

    const selectGoodsModalVisible = ref(false);
    const goodsLoading = ref(false);

    const handleSelectGoods = async (goodsList = []) => {
      goodsLoading.value = true;

      const productIsopen = goodsList.length ? 1 : 0;
      await liveApi.updateProductIsopen({
        id: liveRoomId,
        productIsopen,
      });

      goodsData.value = _.cloneDeep(goodsList);

      const goodsIds = _.map(goodsData.value, "id");

      await liveApi
        .updateGoods({
          id: liveRoomId,
          goodsIds,
        })
        .then(() => {
          message.success("保存成功");
        });

      goodsLoading.value = false;

      selectGoodsModalVisible.value = false;
    };

    const handleDeleteGoods = async (deleteGoods) => {
      await liveApi
        .deleteLiveGoods({
          id: deleteGoods.liveGoodsId,
        })
        .then(() => {
          _.remove(goodsData.value, (item) => {
            return item.id === deleteGoods.id;
          });
          message.success("移除成功");
        });
    };

    const handleGoodsStatusChange = async (value) => {
      if (!goodsData.value.length && value) {
        return;
      }

      let productIsopen = value ? 1 : 0;

      await liveApi.updateProductIsopen({
        id: liveRoomId,
        productIsopen,
      });
      message.success("保存成功");
    };

    // 表单校验

    return {
      lodash: _,
      form,
      pageLoaidng,

      saveSetting,

      selectedCode,
      selectLiveCodeModalVisible,
      handleSelectCode,
      handleChannelCodeChange,

      lessonRemainSelectOption,
      isAccountbind,

      goodsData,
      goodsColumns,
      goodsType,
      goodsStatus,
      selectGoodsModalVisible,
      handleSelectGoods,
      handleDeleteGoods,
      handleGoodsStatusChange,
      goodsLoading,
    };
  },
});
</script>
<style lang='less' scoped>
.liveCode-upload__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 110px;
  height: 110px;
  border-radius: 4px;
  border: 1px dashed @border;

  cursor: pointer;
}

.ant-radio-wrapper {
  &:not(:last-child) {
    margin-right: 44px;
  }
}

:deep(.ant-input) {
  &-affix-wrapper {
    padding-right: 40px;
  }

  &-suffix-wrapper {
    position: relative;
  }

  &-suffix {
    position: absolute;
    bottom: 0;
    right: 4px;
    color: @gray;
    font-size: 12px;
  }
}

.ant-table-wrapper {
  margin-top: 16px;
}

.livecode__show {
  &:hover {
    .icon-bianji {
      display: initial;
    }
  }
}
</style>