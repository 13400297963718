<template>
  <a-spin :spinning='spinning'>
    <router-header :routerHeaderInfo="routerHeaderInfo" />

    <div class="flex">
      <div class="flex relative mr-16 px-16 py-12 w-1/2 bg-color-f5">
        <div class="flex-shrink-0"
             style="width: 200px">
          <a-image :src="liveRoom.liveCover"
                   alt="直播封面"
                   style="width: 200px; aspect-ratio: 16 / 9; border-radius: 8px;" />

          <p class="my-8">
            <span class="inline-block w-72 text-color-999">直播时间:</span>
            <span class="text-color-333 ">
              {{ liveRoom.actualStartTime ?  $f.datetime(liveRoom.actualStartTime, 'YYYY-MM-DD HH:mm') : $f.datetime(liveRoom.startTime, 'YYYY-MM-DD HH:mm') }}</span>
          </p>
        </div>

        <div class="flex-1 flex flex-col ml-8"
             style="min-width: 0">
          <template v-if="liveRoom.title?.length > 30">
            <a-tooltip :title="liveRoom.title">
              <p class="text-16 text-color-333 font-medium truncate">{{ liveRoom.title }}</p>
            </a-tooltip>
          </template>
          <p v-else
             class="text-16 text-color-333 font-medium truncate">{{ liveRoom.title }}</p>
          <div class="flex my-8 text-color-999"
               style="width: 100%; ">
            <span class="flex-shrink-0 w-44">简介:</span>
            <p class="text-color-333 break-all overflow-x-auto"
               style="max-height: 80px;"> {{ liveRoom?.summary || '-' }}</p>
          </div>

          <div class="btn-list flex absolute bottom-12 right-16">
            <a-button type="primary"
                      :disabled="!hasTeacher || !!liveRoom.actualEndTime"
                      @click="goToLive">去直播</a-button>

            <a-button class="mx-12"
                      :disabled="liveRoom.status !== 'unstart'"
                      @click="goToEditLive">
              课程编辑
            </a-button>
            <a-button class=""
                      @click="shareModalVisible = true">分享</a-button>
          </div>
        </div>
      </div>

      <div class="w-1/2 p-16 bg-color-f5">
        <div class="title-mark pl-12 mt-0 mb-32">统计数据</div>

        <div class="flex justify-evenly">
          <a-statistic title="累计观看人数"
                       :value="liveRoom.viewerTotalNum" />

          <a-statistic title="累计评论数"
                       :value="liveRoom.chatTotalNum" />

          <a-statistic title="平均观看时长(分钟)"
                       :value="liveRoom.viewerAvgOnlineDuration" />
        </div>
      </div>
    </div>

    <a-tabs v-model:activeKey="showType">
      <a-tab-pane key="ClassSetting"
                  tab="课程设置" />
      <a-tab-pane key="InteractionSetting"
                  tab="互动设置" />
      <a-tab-pane key="OperationSetting"
                  tab="运营设置" />
      <a-tab-pane key="ViewData"
                  tab="观看数据" />
    </a-tabs>

    <keep-alive>
      <component :is="componentsList[showType]"
                 :key="showType"
                 :live-room="liveRoom"
                 :config="config" />
    </keep-alive>

    <!-- 播放器sdk接口一次性调用，不能放到keep-alive中。单独领出来显示 -->
    <class-setting v-show="showType === 'ClassSetting'"
                   :live-room="liveRoom"
                   :config="config"
                   @changeTeacher=changeTeacher />

  </a-spin>

  <a-modal v-model:visible="shareModalVisible"
           title="分享直播公开课"
           :footer="null"
           width="500px">
    <p class="mb-12 text-color-999">你可以用以下任一方式分享直播课</p>
    <div class="flex items-center">
      <Qrcode :width="120"
              :height="120"
              :margin="0"
              :value="shareLive.link"
              :dotsOptions="{type: 'square', color: '#000'}"
              :cornersSquareOptions="{ type: 'square', color: '#000000' }"
              imgclass="download-img" />
      <div class="flex flex-col items-start ml-8"
           style="line-height: 32px;">
        <a-button type="link"
                  v-copy="shareLive.link">
          1. 复制分享链接
        </a-button>

        <a-button type="link"
                  @click="downloadQrcode(shareLive.title)">
          2. 下载二维码
        </a-button>
        <a-button type="link"
                  @click="messageShare">
          3. 安排员工群发给客户
        </a-button>
      </div>
    </div>
    <p class="mt-8 mb-12 text-color-999">微信扫码进入直播间， 然后分享直播</p>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, computed } from "vue";
import { Statistic, Tooltip, Tabs, Image } from "ant-design-vue";
import { useRoute, useRouter } from "vue-router";
import _ from "lodash";
import Qrcode from "qrcode-vue3";

import RouterHeader from "@/components/RouterHeader.vue";
import ViewData from "@/views/liveOpenClass/statictisSubpages/ViewData";
import ClassSetting from "@/views/liveOpenClass/statictisSubpages/ClassSetting";
import OperationSetting from "@/views/liveOpenClass/statictisSubpages/OperationSetting";
import InteractionSetting from "@/views/liveOpenClass/statictisSubpages/InteractionSetting";
import ShareModal from "@/components/ShareLinkModal";

import { timestampToHour, downloadImg } from "@/global";

import liveApi from "@/service/api/live";

export default defineComponent({
  name: "LiveOpenClassStatistic",

  components: {
    AStatistic: Statistic,
    ATooltip: Tooltip,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AImage: Image,
    Qrcode,

    RouterHeader,
    ViewData,
    ShareModal,
    ClassSetting,
    InteractionSetting,
  },

  emits: ["changeTeacher"],

  setup() {
    const spinning = ref(true);
    const route = new useRoute();
    const { id: roomId, setId } = route.query;

    const routerHeaderInfo = reactive([
      {
        path: "/marketingUtil/liveOpenClassIndex",
        name: "直播公开课",
      },
      { name: "直播详情" },
    ]);

    const hasTeacher = ref(false);

    const changeTeacher = (teacherId) => {
      hasTeacher.value = !!teacherId;
    };

    const liveRoom = reactive({});

    const getLiveRoomInfo = async () => {
      const res = await liveApi.infoRoom({
        roomId,
      });

      _.assign(liveRoom, res);

      if (liveRoom.setId) {
        _.assign(routerHeaderInfo[0], {
          path: "/marketingUtil/liveColumnStatistic",
          query: {
            setId: liveRoom.setId,
          },
          name: "专栏详情",
        });
      }

      hasTeacher.value = !!liveRoom.teacherId;

      spinning.value = false;
    };
    getLiveRoomInfo();

    const config = ref([]);

    const findSetting = async () => {
      const res = await liveApi
        .settingFind({
          liveRoomId: roomId,
        })
        .catch((err) => {
          if (err.code === "40390005") {
            config.value = [];
          }
        });

      config.value = res;
    };

    findSetting();

    const getDuration = function (text) {
      const time = text;
      const timeFormat = timestampToHour(time);

      return `${Number(timeFormat.hours) ? `${timeFormat.hours}时` : ""}${
        Number(timeFormat.minutes) ? `${timeFormat.minutes}分` : ""
      }${timeFormat.seconds}秒`;
    };

    const showType = ref("ClassSetting");

    const componentsList = {
      ViewData,
      OperationSetting,
      InteractionSetting,
    };

    const router = new useRouter();

    const goToEditLive = () => {
      router.push({
        name: "liveOpenClass_create",
        query: { isEdit: true, id: liveRoom.id },
      });
    };

    const goToLive = () => {
      const routeUrl = router.resolve({
        name: "live_enter",
        query: { id: roomId },
      });

      window.open(routeUrl.href, "_blank");
    };

    // 分享直播
    const shareModalVisible = ref(false);
    const shareLive = computed(() => {
      return {
        title: liveRoom.title,
        link: `https://${window.location.host}/customer-h5/livePlay?externalTicket=${liveRoom.externalTicket}&corpId=${liveRoom.corpId}`,
      };
    });

    const messageShare = async () => {
      // 判断是否已创建过群发任务
      const isShared = await liveApi.checkMemberShareLive({
        roomId,
      });

      if (isShared) {
        router.push({
          path: "/marketingUtil/messageShareIndex",
          query: {
            roomId,
            setId,
          },
        });
        return;
      }

      router.push({
        path: "/marketingUtil/messageShareCreate",
        query: {
          roomId,
          setId,
        },
      });
    };

    const downloadQrcode = function (fileName) {
      const img = document.getElementsByClassName("download-img");
      const src = img[0].getAttribute("src");

      downloadImg(src, fileName);
    };

    return {
      lodash: _,
      downloadQrcode,
      spinning,
      routerHeaderInfo,
      liveRoom,

      config,

      getDuration,

      showType,
      componentsList,

      goToEditLive,
      goToLive,

      shareModalVisible,
      shareLive,
      messageShare,

      hasTeacher,
      changeTeacher,
    };
  },

  beforeUnmount() {
    clearInterval(this.timer);

    this.timer = null;
  },
});
</script>
<style lang='less' scoped>
.btn-list .ant-btn {
  padding: 0 16px;
  font-size: 14px;
}
</style>